import React from 'react';

const Logo = ({ onClick }) => {
  return (
    <a href='#hero'>
      <div
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '.7em'
        }}
      >
        <svg id='one' width='33' height='33' viewBox='0 0 150 150' fill='none'>
          <path
            className='path'
            stroke='#1890ff'
            strokeWidth='9'
            strokeLinecap='round'
            d='
               M 10 140 L 75 140 Q 140 140 140 75 Q 140 10 75 10 L 10 10 
               M 10 45 Q 10 70 33 70 L 75 70 Q 97 70 98 45 Q 97 23 75 23 L 33 23 M 10 45 Q 10 23 33 23
               M 10 101 Q 10 126 33 126 L 75 126 Q 97 126 98 101 Q 97 79 75 79 L 33 79 M 10 101 Q 10 79 33 79
               '
          />
        </svg>
      </div>
    </a>
  );
};

export default Logo;
