import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const steps = [
  {
    key: '1',
    title: 'STEP 1: Discuss your goals',
    content:
      'Before starting on your web design, your project manager will get to know your company. Though we’ve worked with companies in many different niches, we know that no two companies are the same, and we want to know everything that makes you unique. We’ll discuss your specific project goals, the audience you want to target, keywords you want to rank for, and your competitors. After we’ve gathered this important information, we’ll mutually agree on dates for us to share with you the progress of your website.',
  },
  {
    key: '2',
    title: 'STEP 2: Plan',
    content:
      'After gaining insight from your existing website, members of our team will work together to create a sitemap that will act as an outline for your website. With the information we’ve gathered, we’ll match you with three designers from our team that fit your design preferences and business niche. Then, it’s time to start!',
  },
  {
    key: '3',
    title: 'STEP 3: Homepage Prototypes',
    content:
      'From there, your designers will get started on creating a prototype of your website’s homepage. Your project manager will pick two of the best designs, and pass them along to you. You’ll always get the final say. After you give a design a go-ahead, your project manager will perform user testing to make sure that the design will generate conversions for your business.',
  },
  {
    key: '4',
    title: 'STEP 4: Inner-page prototypes',
    content:
      'After your homepage is ready to go, your designers will start working on the layout and design of your other site pages. This includes the basic layout for article pages, blogs, forms, and contact pages. You’ll review the prototypes, choose your favorite, and your project manager will run more user tests to ensure that your entire website is promoting conversions – not just your homepage.',
  },
  {
    key: '5',
    title: 'STEP 5: Site buildout and optimization',
    content:
      'After you’ve reviewed every element of your website, our developers will help build out the site and ensure that it’s optimized for search engines, if you’ve also purchased an SEO package.',
  },
  {
    key: '6',
    title: 'STEP 6: Testing',
    content:
      'Once we’ve built your site, we’ll test every aspect on different platforms and browsers to ensure that it performs in a way that will increase your conversions and overall results.',
  },
  {
    key: '7',
    title: 'STEP 7: Review',
    content:
      'You’ll have another chance to review your website design in terms of aesthetics and usability, and submit any edits you may have.',
  },
  {
    key: '8',
    title: 'STEP 8: Launch',
    content:
      'After making all of your desired edits to the website, we’ll do an official site launch and your website will be live!',
  },
];

export default function WorkSteps() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box
      style={{ padding: '1em 2em', overflow: 'auto', height: 'fit-content' }}
      id='works'
      className='heroBlock'
    >
      <h2 className='titleHolder'>How it works?</h2>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((step, index) => (
          <Step key={step.title}>
            <StepLabel
              optional={
                index === 7 ? (
                  <Typography variant='caption'>Last step</Typography>
                ) : null
              }
            >
              {step.title}
            </StepLabel>
            <StepContent>
              <Typography>{step.content}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant='contained'
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} style={{ backgroundColor: 'transparent' }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button variant='contained' onClick={handleReset}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
