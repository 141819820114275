import React from 'react';

import { Row, Col } from 'antd';

const items = [
  {
    key: '1',
    icon: <i className='fas fa-chart-pie'></i>,
    title: 'High Performance',
    content:
      'Everything that converts your website into a lead generation machine is an essential. We will develop your website to ensure that you can get a regular stream of business from it. Fast to load, optimised and secured web pages are the attributes of a powerful website, which leads to high performance. We will integrate your site with the right set of tools, so we can easily track its performance and make changes to it on a regular basis.',
  },
  {
    key: '2',
    icon: <i className='fas fa-desktop'></i>,
    title: 'Flat Design',
    content:
      'We will ensure that your website’s content scales smoothly depending on the device’s screen size. With the use of simple shapes and minimal textures, flat design ensures that responsive designs work well and load fast (especially important since mobile devices have slower internet speeds). By reducing the amount of visual noise, flat design provides users with a streamlined and more optimal user experience.',
  },
  {
    key: '3',
    icon: <i className='fas fa-database'></i>,
    title: 'Simplified Workflow',
    content:
      'For many reasons, you need to ensure that your website is well-structured, has a consistent design and includes all of the necessary information your audience might be searching for. A good web design workflow helps you achieve this, by guiding you through the planning and implementation of every important step for your website.',
  },
];

function AppAbout() {
  return (
    <div id='about' className='block aboutBlock'>
      <div className='container-fluid'>
        <div className='titleHolder'>
          <h2>About Us</h2>
          <p>
            DESI8N is a tech-enabled solutions provider, and we create custom
            strategies for each of our clients based on their needs and goals.
          </p>
        </div>
        <div className='contentHolder'>
          <p>
            The web is our passion, and we’re passionate about helping
            businesses reach their goals. So when you choose DESI8N, you won’t
            get a cookie-cutter strategy — you’ll get a custom plan that fits
            your company, your needs, and your goals.
          </p>
        </div>
        <Row gutter={[16, 16]}>
          {items.map((item) => {
            return (
              <Col md={{ span: 8 }} key={item.key}>
                <div className='content'>
                  <div className='icon'>{item.icon}</div>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

export default AppAbout;
