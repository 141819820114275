import React, { useState } from 'react';
import { Anchor, Drawer, Button } from 'antd';
import SmallLogo from './SmallLogo';
import { CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
const { Link } = Anchor;

function AppHeader() {
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className='container-fluid'>
      <div className='header'>
        <div className='logo'>
          <SmallLogo />
          &emsp;&emsp;
          {!isMobile&&<a href='#hero'>Desi8n</a>}
        </div>
        <div className='mobileHidden'>
          <Anchor targetOffset='65'>
            <Link href='#hero' title='Home' />
            <Link href='#about' title='About' />
            <Link href='#feature' title='Features' />
            <Link href='#works' title='How it works' />
            <Link href='#faq' title='FAQ' />
            <Link href='#pricing' title='Pricing' />
            <Link href='#contact' title='Contact' />
          </Anchor>
        </div>
        <div className='mobileVisible'>
          <Button type='primary' onClick={showDrawer}>
            <i className='fas fa-bars'></i>
          </Button>
          <Drawer
            placement='right'
            onClose={onClose}
            open={visible}
            width='50%'
            closeIcon={<CloseOutlined style={{ marginLeft: '-12px' }} />}
          >
            <Anchor targetOffset='65'>
              <Link href='#hero' title='Home' />
              <Link href='#about' title='About' />
              <Link href='#feature' title='Features' />
              <Link href='#works' title='How it works' />
              <Link href='#faq' title='FAQ' />
              <Link href='#pricing' title='Pricing' />
              <Link href='#contact' title='Contact' />
            </Anchor>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
