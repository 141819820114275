import React from 'react';
import { List, Card } from 'antd';

const data = [
  {
    title: 'Design Services',
    content: [
      {
        price: 'From $499',
        space: 'Graphic Design',
        user: 'Logo Design',
        support: 'Logo Animation',
        backup: 'Business Card Design',
        access: 'Brochure Design',
      },
    ],
  },
  {
    title: 'CMS Website',
    content: [
      {
        price: 'From $999',
        space: 'Beautiful Websites',
        user: 'Responsive Layout (Mobile Friendly)',
        support: 'Custom Graphics',
        backup: 'Search Engine Optimization*',
        access: 'Emails',
      },
    ],
  },
  {
    title: 'Custom Website',
    content: [
      {
        price: 'From $1999',
        space: 'Unique To Your Business',
        user: 'Able To Grow With Your Business',
        support: 'Complete Control Over Your Website',
        backup: '100% Customisable',
        access: 'Better Security And Performance',
      },
    ],
  },
];

function AppPricing() {
  return (
    <div id='pricing' className='block pricingBlock bgGray'>
      <div className='container-fluid'>
        <div className='titleHolder'>
          <h2>Choose a service that fits your needs</h2>
          <p>
            Here is a list of our design services. If you do not see a service
            listed that doesn’t mean we do not do it. Please ask about unlisted
            services by simply emailing us from <a href='#contact'>here</a>.
          </p>
        </div>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card title={item.title}>
                <p className='large'>{item.content[0].price}</p>
                <p>{item.content[0].space}</p>
                <p>{item.content[0].user}</p>
                <p>{item.content[0].support}</p>
                <p>{item.content[0].backup}</p>
                <p>{item.content[0].access}</p>
                <a className='ant-btn ant-btn-primary' href='#contact'>
                  <i href='#contact' className='fab fa-telegram-plane'></i> Get
                  Started
                </a>
              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default AppPricing;
