import React from 'react';
import { BackTop } from 'antd';

function AppFooter() {
  return (
    <div className='container-fluid'>
      <div className='footer'>
        <div className='logo'>
          <svg
            id='two'
            width='100'
            height='100'
            viewBox='0 0 150 150'
            fill='none'
          >
            <path
              className='path'
              stroke='#1890ff'
              strokeWidth='3'
              strokeLinecap='round'
              d='
               M 10 140 L 75 140 Q 140 140 140 75 Q 140 10 75 10 L 10 10 
               M 10 45 Q 10 70 33 70 L 75 70 Q 97 70 98 45 Q 97 23 75 23 L 33 23 M 10 45 Q 10 23 33 23
               M 10 101 Q 10 126 33 126 L 75 126 Q 97 126 98 101 Q 97 79 75 79 L 33 79 M 10 101 Q 10 79 33 79
               '
            />
          </svg>
          <br />
          <a href='#hero'>DESI8N</a>
        </div>
        <ul className='socials'>
          <li>
            <a href='https://www.facebook.com/'>
              <i className='fab fa-facebook-f'></i>
            </a>
          </li>
          <li>
            <a href='https://www.twitter.com/'>
              <i className='fab fa-twitter'></i>
            </a>
          </li>
          <li>
            <a href='https://www.linkedin.com/'>
              <i className='fab fa-linkedin-in'></i>
            </a>
          </li>
          <li>
            <a href='https://www.pinterest.com/'>
              <i className='fab fa-pinterest-p'></i>
            </a>
          </li>
          <li>
            <a href='https://www.instagram.com/'>
              <i className='fab fa-instagram'></i>
            </a>
          </li>
        </ul>
        <div className='copyright'>Copyright &copy; 2022 DESI8N</div>
        <BackTop>
          <div className='goTop'>
            <i className='fas fa-arrow-circle-up'></i>
          </div>
        </BackTop>
      </div>
    </div>
  );
}

export default AppFooter;
