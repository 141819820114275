import React, { useState, useRef } from 'react';
import { Form, Input, Button, Alert, Spin } from 'antd';
// import axios from 'axios';
import emailjs from '@emailjs/browser';

function AppContact() {
  const form = useRef();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [toSend, setToSend] = useState({
    fullName: '',
    email: '',
    phone: '',
    businessName: '',
    subject: '',
    message: '',
  });

  const sendEmail = (e) => {
    if (toSend.fullName === '' || toSend.email === '' || toSend.message === '')
      return;
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setToSend({
      fullName: '',
      email: '',
      phone: '',
      businessName: '',
      subject: '',
      message: '',
    });
  };

  // const handleSend = async () => {
  //   if (toSend.fullName === '' || toSend.email === '' || toSend.message === '')
  //     return;
  //   setLoading(true);
  //   try {
  //     await axios.post('https://desi8n.herokuapp.com/api/contacts', {
  //       data: {
  //         fullName: toSend.fullName,
  //         email: toSend.email,
  //         phone: toSend.phone,
  //         businessName: toSend.businessName,
  //         subject: toSend.subject,
  //         message: toSend.message,
  //       },
  //     });
  //   } catch (error) {
  //     console.log('ERROR: ', error);
  //     return;
  //   }

  //   setSent(true);
  // };

  return (
    <div id='contact' className='block contactBlock'>
      <div className='container-fluid'>
        <div className='titleHolder'>
          <h2>Get in Touch</h2>
          <p>Want to get in touch? We'd love to hear from you.</p>
          <p>
            Use the form below to drop us an email. Or email us on{' '}
            <a href='mailto:info@desi8n.au'>info@desi8n.au</a>
          </p>
        </div>
        {!sent ? (
          !loading ? (
            <>
              <form
                style={{ display: 'none' }}
                ref={form}
                onSubmit={sendEmail}
                className='login-form'
              >
                <label>Name</label>
                <input
                  type='text'
                  name='user_name'
                  defaultValue={toSend.fullName}
                />
                <label>Email</label>
                <input
                  type='email'
                  name='user_email'
                  defaultValue={toSend.email}
                />
                <label>Phone</label>
                <input
                  type='phone'
                  name='user_phone'
                  defaultValue={toSend.phone}
                />
                <label>Business Name</label>
                <input
                  type='businessName'
                  name='user_businessName'
                  defaultValue={toSend.businessName}
                />
                <label>Subject</label>
                <input
                  type='subject'
                  name='user_subject'
                  defaultValue={toSend.subject}
                />
                <label>Message</label>
                <textarea name='message' defaultValue={toSend.message} />
                <input id='primaryButton' type='submit' value='Send' />
              </form>
              <Form
                name='normal_login'
                className='login-form'
                initialValues={{ remember: true }}
              >
                <Form.Item
                  name='fullname'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your full name!',
                    },
                  ]}
                >
                  <Input
                    required
                    placeholder='Full Name'
                    value={toSend.fullName}
                    onChange={(e) =>
                      setToSend({ ...toSend, fullName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input
                    type='email'
                    required
                    placeholder='Email Address'
                    value={toSend.email}
                    onChange={(e) =>
                      setToSend({ ...toSend, email: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item name='telephone'>
                  <Input
                    placeholder='Telephone'
                    value={toSend.phone}
                    onChange={(e) =>
                      setToSend({ ...toSend, phone: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item name='businessName'>
                  <Input
                    placeholder='Business Name'
                    value={toSend.businessName}
                    onChange={(e) =>
                      setToSend({ ...toSend, businessName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item name='subject'>
                  <Input
                    placeholder='Subject'
                    value={toSend.subject}
                    onChange={(e) =>
                      setToSend({ ...toSend, subject: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item name='message'>
                  <textarea
                    required
                    className='ant-input'
                    rows='4'
                    placeholder='Message'
                    value={toSend.message}
                    onChange={(e) =>
                      setToSend({ ...toSend, message: e.target.value })
                    }
                  />
                </Form.Item>
                <div
                  name='checkbox'
                  style={{
                    height: '24px',
                    display: 'flex',
                    marginBottom: '24px',
                  }}
                >
                  <input
                    required
                    checked={checked}
                    type='checkbox'
                    onChange={() => setChecked(!checked)}
                    style={{
                      height: '24px',
                      width: 'fit-content',
                      cursor: 'pointer',
                      // filter: 'hue-rotate(240deg)',
                    }}
                  />
                  <p
                    style={{
                      height: '24px',
                      width: 'fit-content',
                      marginLeft: '8px',
                    }}
                  >
                    {' '}
                    I agree with terms and conditions.
                  </p>
                </div>
                <Form.Item>
                  <Button
                    disabled={
                      toSend.fullName === '' ||
                      toSend.email === '' ||
                      toSend.message === ''
                    }
                    type='submit'
                    htmlType='submit'
                    className='login-form-button'
                    onClick={() =>
                      document.getElementById('primaryButton').click()
                    }
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Spin size='large' />
            </div>
          )
        ) : (
          <>
            <Alert
              message='Thank you for getting in touch!'
              description='We appreciate you contacting us. One of our colleagues will get back in touch with you soon! Have a great day!'
              type='success'
              showIcon
              closable
              onClose={() => {
                setSent(false);
                setLoading(false);
              }}
            />
            <br />
          </>
        )}
      </div>
    </div>
  );
}

export default AppContact;
