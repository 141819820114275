import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

function AppFaq() {
  return (
    <div id='faq' className='block faqBlock'>
      <div className='container-fluid'>
        <div className='titleHolder'>
          <h2>Frequently Asked Questions</h2>
          <p>
            Here are a few of the questions we get the most. If you don't see
            what's on your mind, reach out to us anytime from{' '}
            <a href='#contact'>here</a>.
          </p>
        </div>
        <Collapse defaultActiveKey={['1']}>
          <Panel header='How long does it take to build a website?' key='1'>
            <p>
              On average, websites launch within 4 to 6 weeks from project
              start. If you are trying to meet a specific deadline, let us know
              and we can provide you with options.
            </p>
          </Panel>
          <Panel
            header='Do your websites work on mobile devices (responsive)?'
            key='2'
          >
            <p>
              Yes, we believe our mobile designs are some of the best you'll
              find anywhere. We put a high emphasis on making sure the website
              experience on a mobile device is second-to-none.
            </p>
          </Panel>
          <Panel
            header='Do you outsource your projects or send work overseas?'
            key='3'
          >
            <p>
              All of our work is performed in-house by our Queensland based
              team. No subcontractors and no outsourcing.
            </p>
          </Panel>
          <Panel header='Which areas do you operate in?' key='4'>
            <p>
              We help clients who are located in Australia. While we would love
              to help businesses in other countries, we've found the timezone
              and marketing differences make us less effective.
            </p>
          </Panel>
          <Panel
            header='Can you revise my current website or finish my incomplete website?'
            key='5'
          >
            <p>
              Typically, we cannot. Our process and tooling are specifically
              designed for the websites we build. However, if there are elements
              you want to retain from an existing website, it's often possible
              for us to recreate them in a new website
            </p>
          </Panel>
        </Collapse>
        <div className='quickSupport'>
          <h3>Want quick support?</h3>
          <p>We're here to answer any questions you may have!</p>
          <a className='ant-btn ant-btn-primary' href='#contact'>
            <i href='#contact' className='fas fa-envelope'></i> Email your
            question
          </a>
        </div>
      </div>
    </div>
  );
}

export default AppFaq;
