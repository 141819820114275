import React from 'react';
import { Checkbox } from 'antd';
import { Carousel } from 'antd';
// import image1 from '../../assets/images/online_test_re_kyfx.svg';
// import image2 from '../../assets/images/content_team_re_6rlg.svg';
// import image3 from '../../assets/images/interior_design_re_7mvn.svg';
// import image4 from '../../assets/images/proud_coder_re_exuy.svg';

const items = [
  {
    key: '1',
    title: 'Web and mobile applications built for everyone',
    content:
      'No matter your industry, impressive website design is important in today’s digital age. If you’re looking to take your business to the next level, you need a custom web design to help you attract and convert more customers. At DESI8N we deliver stunning, responsive designs that are created with conversion in mind.',
  },
  {
    key: '2',
    title: 'Work together',
    content:
      'At DESI8N, we have a specific process that allows us to provide our clients with the best possible results from their websites. When you partner with us, our designers will work to deliver a website that reflects your brand, sets you apart from your competitors, and helps you reach your company’s goals. We use multiple steps to learn about your company and create a site that matches your goals and increases conversions.',
  },
  {
    key: '3',
    title: 'The best app to increase your productivity',
    content:
      'Our web design packages uses cutting-edge web design technology to deliver an experience your users will love. Our web designers build every website with your customers point of view in mind. We know that it only takes 5 seconds for users to decide whether or not to trust a website. And, that your web design is the deciding factor. Our web designers will assess your business, your customers, and develop a design that delivers results.',
  },
];

function AppHero() {
  return (
    <div id='hero' className='heroBlock'>
      <Carousel autoplay>
        {items.map((item, i) => {
          return (
            <>
              <div key={i} className='container-fluid'>
                <div className='content'>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                  <div className='btnHolder'>
                    <a className='ant-btn ant-btn-primary' href='#works'>
                      Learn More
                    </a>{' '}
                    <a className='ant-btn ant-btn' href='#contact'>
                      <i href='#contact' className='fas fa-desktop'></i> Get in
                      Touch
                    </a>
                  </div>
                  <div style={{ height: '32px' }} />
                  <Checkbox checked>
                    No startup costs, no upfront investment.
                  </Checkbox>
                  <Checkbox checked style={{ marginLeft: '-1px' }}>
                    You pay nothing if you don't love your website.
                  </Checkbox>
                  <Checkbox checked style={{ marginLeft: '-1px' }}>
                    No credit card required to get started.
                  </Checkbox>
                  <Checkbox checked style={{ marginLeft: '-1px' }}>
                    Every service is backed by our 30 day money-back guarantee.
                  </Checkbox>
                  {/* <img
                    src={i === 0 ? image1 : i === 1 ? image2 : image3}
                    alt='desi8n'
                    className='mainImage'
                  /> */}
                </div>
              </div>
            </>
          );
        })}
      </Carousel>
    </div>
  );
}

export default AppHero;
